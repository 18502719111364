{
  "dev": {
    "config": {
      "apiKey": "AIzaSyC0TTta5egRJ0LxDSeEvd8BhLrGG9IPQRk",
      "authDomain": "mlc-dev.firebaseapp.com",
      "databaseURL": "https://mlc-dev.firebaseio.com",
      "projectId": "mlc-dev",
      "storageBucket": "mlc-dev.appspot.com",
      "messagingSenderId": "526507237982",
      "clientId": "526507237982-ifuldkju45gs6nrrg5dosii1pna5eqa0.apps.googleusercontent.com"
    },
    "provider": {
      "appId": "1:526507237982:web:d77024f420a69c40",
      "measurementId": "G-QPMXWGJEXQ"
    },
    "attorney": {
      "appId": "1:526507237982:web:73c1f0edec7aa91e908acf",
      "measurementId": "G-DBL7EE84FE"
    },
    "providerAdmin": {
      "appId": "1:526507237982:web:f44c0c6d8742e775908acf",
      "measurementId": "G-3YT0DP0WS7"
    },
    "historian": {
      "appId": "1:526507237982:web:23202308cd0e9c38",
      "measurementId": "G-2PFG1WKBCM"
    },
    "diagnostics": {
      "appId": "1:526507237982:web:45ced1d37afef52c908acf",
      "measurementId": "G-6H7VX3DCES"
    },
    "pmd": {
      "appId": "1:526507237982:web:5db3a508626c5006908acf",
      "measurementId": "G-VB1J0SZBY4"
    },
    "patient": {
      "appId": "1:526507237982:web:c5b2f49a2646b68d908acf",
      "measurementId": "G-DHHB1BVE78"
    },
    "caseVue": {
      "appId": "1:526507237982:web:7a12bd5489b405c6908acf",
      "measurementId": "G-QHP2TJG53H"
    },
    "insights": {
      "appId": "1:526507237982:web:90b7f54a6ff0c71d908acf",
      "measurementId": "G-W9T3DBRN2D"
    },
    "superAdmin": {
      "appId": "1:526507237982:web:33335268b6bf86bd908acf",
      "measurementId": "G-T118RNZF3H"
    }
  },
  "demo": {
    "config": {
      "apiKey": "AIzaSyBcQsp_6jN0pOs7HaoUZZeYvIf3zesOd2I",
      "authDomain": "mlc-demo.firebaseapp.com",
      "databaseURL": "https://mlc-demo-default-rtdb.firebaseio.com",
      "projectId": "mlc-demo",
      "storageBucket": "mlc-demo.appspot.com",
      "messagingSenderId": "1092256480351"
    },
    "provider": {
      "appId": "1:1092256480351:web:bcfb693d7e3effa0c4afea",
      "measurementId": "G-6NBHK0EFV4"
    },
    "attorney": {
      "appId": "1:1092256480351:web:9cc1b0cfca0f47fcc4afea",
      "measurementId": "G-1CP8B91KLF"
    },
    "historian": {
      "apiKey": "AIzaSyBcQsp_6jN0pOs7HaoUZZeYvIf3zesOd2I",
      "authDomain": "mlc-demo.firebaseapp.com",
      "databaseURL": "https://mlc-demo-default-rtdb.firebaseio.com",
      "projectId": "mlc-demo",
      "storageBucket": "mlc-demo.appspot.com",
      "messagingSenderId": "1092256480351",
      "appId": "1:1092256480351:web:03efa1d61a2568b8c4afea",
      "measurementId": "G-NHJTX4B90Z"
    },
    "pmd": {
      "appId": "1:1092256480351:web:6c44905109d876b4c4afea",
      "measurementId": "G-PZF0P2XD4W"
    },
    "caseVue": {
      "appId": "1:1092256480351:web:2e754a6f0c986392c4afea",
      "measurementId": "G-65S3FR1N5B"
    },
    "insights": {
      "appId": "1:1092256480351:web:82c0a6f17e3b706bc4afea",
      "measurementId": "G-S84LW6WGZC"
    }
  },
  "uat": {
    "config": {
      "apiKey": "AIzaSyAcGv8NaVpoy0qf8bxNxfcgrjKwKMcj-7A",
      "authDomain": "mlc-uat.firebaseapp.com",
      "databaseURL": "https://mlc-uat.firebaseio.com",
      "projectId": "mlc-uat",
      "storageBucket": "mlc-uat.appspot.com",
      "messagingSenderId": "1091571677990",
      "appId": "1:1091571677990:web:981db9ea0e60be45"
    },
    "providerAppId": "1:1091571677990:web:981db9ea0e60be45",
    "historian": { "appId": "1:1091571677990:web:377b1dae260bd33e" },
    "diagnostics": {
      "appId": "1:526507237982:web:45ced1d37afef52c908acf",
      "locationId": "us-central",
      "apiKey": "AIzaSyC0TTta5egRJ0LxDSeEvd8BhLrGG9IPQRk",
      "measurementId": "G-6H7VX3DCES"
    }
  },
  "production": {
    "config": {
      "apiKey": "AIzaSyBc_bgXKqLhW6O4a2Ep2XAV2uYJ-IxLKAA",
      "authDomain": "prod-mlc.firebaseapp.com",
      "databaseURL": "https://prod-mlc-default-rtdb.firebaseio.com",
      "projectId": "prod-mlc",
      "storageBucket": "prod-mlc.appspot.com",
      "messagingSenderId": "964636746264",
      "appId": "1:964636746264:web:d3c4a574c041e586ee196d",
      "measurementId": "G-RQ1XQVZS45"
    },
    "provider": {
      "appId": "1:964636746264:web:2b09e6f19fc5dbdeee196d",
      "measurementId": "G-6KHQXP6GZN"
    },
    "attorney": {
      "appId": "1:964636746264:web:d35b40b13a766a65ee196d",
      "measurementId": "G-P9HFFBX1Y7"
    },
    "providerAdmin": {
      "appId": "1:964636746264:web:d3c4a574c041e586ee196d",
      "measurementId": "G-RQ1XQVZS45"
    },
    "historian": {
      "apiKey": "AIzaSyBc_bgXKqLhW6O4a2Ep2XAV2uYJ-IxLKAA",
      "authDomain": "prod-mlc.firebaseapp.com",
      "databaseURL": "https://prod-mlc-default-rtdb.firebaseio.com",
      "projectId": "prod-mlc",
      "storageBucket": "prod-mlc.appspot.com",
      "messagingSenderId": "964636746264",
      "appId": "1:964636746264:web:6eb36c207e8e85bdee196d",
      "measurementId": "G-V4B2BDWJ58"
    },
    "diagnostics": {
      "appId": "1:964636746264:web:8dcf318db7355a73ee196d",
      "measurementId": "G-JWQ8CMPR81"
    },
    "pmd": {
      "appId": "1:526507237982:web:5db3a508626c5006908acf",
      "measurementId": "G-VB1J0SZBY4"
    },
    "caseVue": {
      "appId": "1:964636746264:web:8b1dbdb516e648cfee196d",
      "measurementId": "G-DP1MRCYYW1"
    },
    "insights": {
      "appId": "1:1092256480351:web:82c0a6f17e3b706bc4afea",
      "measurementId": "G-S84LW6WGZC"
    }
  }
}
