// src/app/fcm.service.ts
import { inject, Injectable } from '@angular/core';
import { getMessaging, getToken, Messaging, onMessage } from '@angular/fire/messaging';
import { environment } from '../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { NotificationService } from './notification.service';
@Injectable({
    providedIn: 'root',
})
export class FcmService {
    private messaging = inject(Messaging);
    private authService = inject(AuthService);
    private NotificationService = inject(NotificationService)

    constructor() {
        this.messaging = getMessaging();
    }

    async requestPermission(): Promise<void> {
        try {
            const permission = await Notification.requestPermission();
            if (permission === 'granted') {
                const registration = await navigator.serviceWorker.register('/ngsw-worker.js');
                const token = await getToken(this.messaging, {
                    vapidKey: environment.db.pmd.vapidKey,
                    serviceWorkerRegistration: registration,
                });

                if (token) {
                    console.log('FCM Token:', token);
                    this.listenForMessages();
                    setTimeout(() => {
                        this.authService.saveFcmToken(token)
                            .then(() => console.log('Checked and saved device token if needed'))
                            .catch((error) => console.error('Error saving device token:', error));
                    }, 3000);
                    // TODO: Send the token to your backend or Firestore
                } else {
                    console.warn('No registration token available. Request permission to generate one.');
                }
            }
        } catch (error) {
            console.error('Error requesting permission:', error);
        }
    }

    listenForMessages(): void {
        onMessage(this.messaging, (payload) => {
            console.log('Foreground message received:', payload);
            if (payload.data) {
                new Notification(payload.data['title'], {
                    body: payload.data['body'],
                });
            }
            this.NotificationService.saveNotification(payload);
        });
    }

}
