import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { map } from 'rxjs';
import { AuthService } from './auth/auth.service';

/**
 * Auth guard that checks if the user is logged in.
 * If the user is not logged in, it redirects to the login page.
 * @param route The route that the user is trying to navigate to.
 * @param state The state of the router.
 * @returns An observable that resolves to true if the user is logged in and false otherwise.
 */
export const authGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const authService = inject(AuthService);
  return authService.isValidLogin().pipe(
    map((valid) => {
      return valid || router.parseUrl('/login');
    })
  );
};
